import axios from "axios";
import config from "../config/bitacora.config";

const traerListadoBitacora = () => axios.get(`${config.host}`);
const traerListadoBitacoraDeHoy = (startDate, endDate) =>
  axios.get(`${config.host}`, { params: { startDate, endDate } });
const traerBitacoraPorId = (dispositivoId) =>
  axios.get(`${config.host}`, { params: { dispositivoId } });
const traerInfoBitacora = (id) => axios.get(`${config.host}/${id}`);
const editarBitacora = (id, justification) =>
  axios.put(`${config.host}/${id}`, justification);
const traerListadoBitacoraTres = () =>
  axios.get(`${config.host}${"?page=1&limit=5"}`);

export default {
  traerListadoBitacora,
  traerBitacoraPorId,
  traerListadoBitacoraDeHoy,
  traerInfoBitacora,
  editarBitacora,
  traerListadoBitacoraTres,
};
