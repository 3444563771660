<template>
  <div class="row">
    <div class="d-md-flex d-sm-block">
      <div class="col-md-8 col-sm-12">
        <div class="p-2">
          <div class="card p-lg-10 p-sm-5" style="height: 80vh">
            <div class="d-flex align-items-center">
              <h2 class="fw-bolder text-blue m-0">Bitácora</h2>
              <div
                class="d-flex rounded-pill py-2 px-4 align-items-center w-auto mx-3"
                style="background-color: #f4f8fb"
                v-if="equipo._id"
              >
                <img
                  src="media/logos/logo_sens_blue.svg"
                  alt="logo"
                  style="height: 20px"
                />
                <h4 class="text-blue mx-2 my-0">1234{{ equipo.nombre }}</h4>
                <p class="m-0 fw-normal">{{ equipo.area.nombre }}</p>
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-end">
              <div>
                <div class="d-flex">
                  <input
                    class="form-control text-secondary border border-secondary p-2"
                    type="date"
                    style="width: 231px"
                    v-model="fechaSelect"
                  />
                  <button
                    @click="mostrarBitacorasPorFecha"
                    class="btn bg-blue rounded-pill border-0 mx-3 px-4 py-2 text-white"
                  >
                    Buscar
                  </button>
                </div>
              </div>
              <div class="h-100">
                <button
                  @click="mostrarListadoHoy"
                  class="btn rounded-circle p-0 justify-content-center align-items-center"
                  style="width: 55px; height: 54px; border: 1px solid #009ef6"
                >
                  <i class="bi bi-calendar-date text-blue fs-1 p-0"></i>
                </button>
              </div>
            </div>
            <!-- Bitacora listado inicio -->
            <div
              class="mt-3"
              style="height: calc(100% - 84px); overflow-y: auto"
            >
              <div v-for="(bitacora, i) in listadoBitacoras" :key="i">
                <router-link
                  class="card p-5 my-5 shadow-none"
                  style="background-color: #f4f8fb"
                  :to="{
                    name: 'profile-bitacora-chat',
                    params: { id: bitacora._id },
                  }"
                >
                  <div class="d-flex">
                    <div
                      class="rounded-circle bg-danger p-4 me-5 d-flex align-items-center"
                      style="width: 45px; height: 45px"
                    >
                      <img
                        alt=""
                        class="img-fluid me-5"
                        src="/media/svg/files/document_bitacora.svg"
                      />
                    </div>
                    <div>
                      <h4
                        class="fw-bolder"
                        style="color: #6ace53"
                        v-if="bitacora.justification"
                      >
                        (Falla resuelta)
                      </h4>
                      <h4
                        v-if="bitacora.justification == null"
                        class="fw-bolder text-danger"
                      >
                        (Pendiente respuesta)
                      </h4>
                      <p class="text-dark">
                        {{ bitacora.message }}
                      </p>
                      <span type="date" class="text-blue">{{
                        fecha(bitacora.updatedAt)
                      }}</span>
                    </div>
                  </div>
                </router-link>
              </div>
              <span class="text-danger" v-if="listadoBitacoras.length === 0"
                >No se encuentran bitacoras en la fecha seleccionada</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12">
        <div class="p-2">
          <div class="card p-md-5 p-sm-2" style="height: 60vh">
            <div class="d-flex align-items-center">
              <i class="bi bi-search me-4"></i>
              <input
                type="text"
                class="form-control"
                placeholder="Ingrese el nombre del equipo"
              />
            </div>
            <div class="my-3" style="overflow-y: auto">
              <div class="my-4" v-for="(sensor, j) in listadoSensores" :key="j">
                <button
                  @click="equipoSelect(sensor)"
                  class="btn w-100 d-flex align-items-start p-0"
                >
                  <div
                    class="rounded-circle bg-blue p-2 me-5 d-flex align-items-center"
                    style="width: 45px; height: 45px"
                  >
                    <img
                      alt=""
                      class="img-fluid me-5"
                      src="/media/logos/logo_sens_white.svg"
                    />
                  </div>
                  <div class="text-start">
                    <h4 class="fw-bolder text-blue">{{ sensor.nombre }}</h4>
                    <p>{{ sensor.area.nombre }}</p>
                    <span style="color: #a7a7a7"
                      >125 Bitácoras - 25 bitácoras sin leer</span
                    >
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import listado from "@/services/bitacora.service";
import equipos from "@/services/equipos.service";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import es from "dayjs/locale/es";

export default defineComponent({
  name: "bitacora",
  components: {},
  setup() {
    const listadoBitacoras = ref([]);
    const listadoSensores = ref([]);
    const traerListadoBitacoras = () => {
      listado.traerListadoBitacora().then((res) => {
        listadoBitacoras.value = res.data.data;
      });
    };
    const fecha = (valor) => {
      dayjs.extend(utc);
      dayjs.extend(timezone);
      dayjs.locale(es);
      return dayjs.tz(valor, "America/Bogota").format("dddd, MMM D - HH:mm");
    };
    const mostrarListadoHoy = () => {
      dayjs.extend(utc);
      const fechaInicial = dayjs.utc().format("YYYY-MM-DDT00:00:00");
      const fechaFinal = dayjs.utc().format("YYYY-MM-DDT23:59:59");
      listado
        .traerListadoBitacoraDeHoy(fechaInicial, fechaFinal)
        .then((res) => {
          listadoBitacoras.value = res.data.data;
        });
    };
    const fechaSelect = ref("");
    const mostrarBitacorasPorFecha = () => {
      dayjs.extend(utc);
      const fechaInicial = dayjs(fechaSelect.value).format(
        "YYYY-MM-DDT00:00:00"
      );
      const fechaFinal = dayjs(fechaSelect.value).format("YYYY-MM-DDT23:59:59");

      listado
        .traerListadoBitacoraDeHoy(fechaInicial, fechaFinal)
        .then((res) => {
          listadoBitacoras.value = res.data.data;
        });
    };
    const traerListadoSensores = () => {
      equipos.traerTodosLosDispositivos().then((res) => {
        listadoSensores.value = res.data;
      });
    };
    const equipo = ref({});
    const equipoSelect = (sensor) => {
      listado.traerBitacoraPorId(sensor._id).then((res) => {
        listadoBitacoras.value = res.data.data;
      });
      equipo.value = sensor;
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Campaigns", ["Pages", "Profile"]);
      traerListadoBitacoras();
      traerListadoSensores();
    });
    return {
      listadoBitacoras,
      fecha,
      mostrarListadoHoy,
      mostrarBitacorasPorFecha,
      fechaSelect,
      listadoSensores,
      equipoSelect,
      equipo,
    };
  },
});
</script>
